@import '../../styles/common';

.EntryInstructions {
  display: flex;
  justify-content: center;

  &__container {
    text-align: left;
    max-width: $table-max-width;
  }
}
