@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');

$table-max-width: 750px;
$scores-yellow: #ffcc00;
$scores-red: #f12d2d;
$row-height: 50px;

select {
  font-size: 17px;
  border-radius: 15px;
}

input {
  font-size: 17px;
}

@mixin center-vertical-and-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin background-stripe-color($color) {
  background: linear-gradient(
    transparent 30%,
    $color 30%,
    $color 70%,
    transparent 70%,
    transparent 100%
  );
}

@mixin team-scoreboard-style() {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: $row-height;

  &--top {
    @include background-stripe-color($scores-red);
  }

  &--bottom {
    @include background-stripe-color($scores-yellow);
  }
}
