.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
  * {
    font-size: calc(10px + 2vmin);
  }
}

.App-footer {
  position: absolute;
  bottom: 0;

  a {
    color: lightblue;
  }
}
