@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap);
select{font-size:17px;border-radius:15px}input{font-size:17px}.Scoreboard{margin:0 auto;display:grid;grid-auto-flow:column;grid-template-rows:repeat(3, 50px);border-top:1px solid #fff;border-right:1px solid #fff;max-width:750px}.Scoreboard .team{display:block;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;line-height:50px}.Scoreboard .team--top{background:linear-gradient(transparent 30%, #f12d2d 30%, #f12d2d 70%, transparent 70%, transparent 100%)}.Scoreboard .team--bottom{background:linear-gradient(transparent 30%, #fc0 30%, #fc0 70%, transparent 70%, transparent 100%)}.Scoreboard>div{font-family:'Roboto Slab', serif;border-left:1px solid #fff;border-bottom:1px solid #fff;min-width:25px;display:flex;flex-direction:row;justify-content:center;align-items:center}.Scoreboard .end-score--top{background:linear-gradient(transparent 30%, #f12d2d 30%, #f12d2d 70%, transparent 70%, transparent 100%)}.Scoreboard .end-score--bottom{background:linear-gradient(transparent 30%, #fc0 30%, #fc0 70%, transparent 70%, transparent 100%)}.Scoreboard--consistent-width{grid-template-columns:200px;border:3px solid black}

select{font-size:17px;border-radius:15px}input{font-size:17px}.AlertModal{position:fixed;top:0;height:100%;width:100%;background-color:rgba(54,54,54,0.75)}.AlertModal .alert-card{margin:5vh auto;min-height:90vh;width:75%;background-color:rgba(119,208,232,0.8);display:flex;flex-direction:row;justify-content:center;align-items:center;flex-direction:column}.AlertModal .loading-animation{font-size:50px;margin:0}@-webkit-keyframes blink{0%{opacity:0.2}20%{opacity:1}100%{opacity:0.2}}@keyframes blink{0%{opacity:0.2}20%{opacity:1}100%{opacity:0.2}}.AlertModal .loading-animation__dot{-webkit-animation-name:blink;animation-name:blink;-webkit-animation-duration:1.4s;animation-duration:1.4s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-fill-mode:both;animation-fill-mode:both}.AlertModal .loading-animation__dot:nth-child(2){-webkit-animation-delay:0.2s;animation-delay:0.2s}.AlertModal .loading-animation__dot:nth-child(3){-webkit-animation-delay:0.4s;animation-delay:0.4s}

select{font-size:17px;border-radius:15px}input{font-size:17px}

select{font-size:17px;border-radius:15px}input{font-size:17px}.HistoricalScores .historical-scores__scoreboard{margin-bottom:10px}

select{font-size:17px;border-radius:15px}input{font-size:17px}.GameSummary{display:flex;justify-content:center;padding-top:20px}.GameSummary .team-summary{flex:1 1;border:1px solid;font-size:20px;font-weight:bold;display:flex;flex-direction:column;margin:0 50px}.GameSummary .team-summary:last-of-type{margin-left:0}.GameSummary .team-summary__team{flex:1 0}.GameSummary .team-summary__team--top{background-color:#f12d2d}.GameSummary .team-summary__team--bottom{background-color:#fc0}.GameSummary .team-summary__score{font-size:40px}

select{font-size:17px;border-radius:15px}input{font-size:17px}.standings-page .standings-container{display:flex;justify-content:center;margin-top:20px}.standings-page .standings-td{width:30px}.standings-page .standings-td--index{font-size:x-small;padding-right:5px;font-weight:bold}.standings-page .standings-td--name{text-align:left;max-width:150px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}

select{font-size:17px;border-radius:15px}input{font-size:17px}

select{font-size:17px;border-radius:15px}input{font-size:17px}.EntryInstructions{display:flex;justify-content:center}.EntryInstructions__container{text-align:left;max-width:750px}

.App{text-align:center}.App-logo{height:10vmin}.App-header{min-height:10vh;display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:10px}.App-header *{font-size:calc(10px + 2vmin)}.App-footer{position:absolute;bottom:0}.App-footer a{color:lightblue}

