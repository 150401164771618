@import '../../styles/common';

$border-color: white;
$border: 1px solid $border-color;

.Scoreboard {
  margin: 0 auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(3, $row-height);
  border-top: $border;
  border-right: $border;

  .team {
    @include team-scoreboard-style;
  }

  > div {
    font-family: 'Roboto Slab', serif;
    border-left: $border;
    border-bottom: $border;
    min-width: 25px;

    @include center-vertical-and-horizontal;
  }

  max-width: $table-max-width;

  .end-score {
    &--top {
      @include background-stripe-color($scores-red);
    }

    &--bottom {
      @include background-stripe-color($scores-yellow);
    }
  }

  &--consistent-width {
    grid-template-columns: 200px;
    border: 3px solid black;
  }
}
