@import '../../styles/common';

.GameSummary {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .team-summary {
    flex: 1 1 0;
    border: 1px solid;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    margin: 0 50px;

    &:last-of-type {
      margin-left: 0;
    }

    &__team {
      flex: 1 0 0;
      &--top {
        background-color: $scores-red;
      }

      &--bottom {
        background-color: $scores-yellow;
      }
    }

    &__score {
      font-size: 40px;
    }
  }
}
