@import '../../styles/common';

.AlertModal {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(54, 54, 54, 0.75);

  .alert-card {
    margin: 5vh auto;
    min-height: 90vh;
    width: 75%;
    background-color: rgba(119, 208, 232, 0.8);
    @include center-vertical-and-horizontal;
    flex-direction: column;
  }

  .loading-animation {
    font-size: 50px;
    margin: 0;

    @keyframes blink {
      0% {
        opacity: 0.2;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }

    &__dot {
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
}
