@import '../../styles/common';

.standings-page {
  .standings-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .standings-td {
    width: 30px;

    &--index {
      font-size: x-small;
      padding-right: 5px;
      font-weight: bold;
    }

    &--name {
      text-align: left;
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
